<template>
    <!-- 新建banner页面 -->
    <div class="positionT0L0">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 10 }">
            <a-form-model-item label="海报名称" prop="name" class="por">
                <a-input v-model="form.name" :maxLength="32" placeholder="请输入海报名称">
                    <div slot="addonAfter">{{ form.name.length }}/32</div>
                </a-input>
            </a-form-model-item>

            <a-form-model-item label="海报图片" prop="imageUrl">
                <UpdataImg @avatarfns="imgUrlFn" :imageUrlNum="1" :imgUrl="imageUrls"></UpdataImg>
                <div class="ant-form-explain" v-if="imgUrlFnShow" style="color: #f5222d">请上传图片</div>
            </a-form-model-item>

            <a-form-model-item label="排序" prop="sort">
                <a-input-number v-model="form.sort" placeholder="请输入排序" style="width:100%" :max="99999999"/>
            </a-form-model-item>

            <a-form-model-item ref="putawayFlag	" label="上架设置" prop="putawayFlag">
                <a-radio-group :value="form.putawayFlag" @change="putawayFlagChange">
                    <a-radio :value="2">立即上架</a-radio>
                    <a-radio :value="3">暂不上架</a-radio>
                </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label="对应内容" class="product" :class="{ 'has-error': isCheckCouseNo }" required>
                <div class="product-box" v-if="mdl">
                    <a-icon v-if="this.$route.params.id == 0" type="close-circle" :style="{ fontSize: '20px', color: '#9FA5AF' }" class="product-close" @click="closeHandle"/>
                    <div class="product-wrap flexbox justify middle" :class="{ bg: this.$route.params.id != 0 }">
                        <div class="left-wrap flex1 flexbox left">
                            <div class="product-img">
                                <img class="p-img" v-if="mdl.coverUrl" :src="mdl.coverUrl" alt=""/>
                                <img class="p-img" v-if="mdl.coverImg" :src="mdl.coverImg" alt=""/>
                            </div>
                            <div class="product-info flex1">
                                <div style="height: 56px">
                                    <div class="p-title more-t">{{ form.businessName }}</div>
                                    <div v-show="form.businessType == 1 && mdl.pricingType == 0" class="p-price">免费</div>
                                    <div v-show="form.businessType == 1 && mdl.pricingType == 1" class="p-price">￥{{ mdl.price }}</div>
                                    <div v-show="form.businessType == 1 && mdl.pricingType == 2" class="p-price">不支持单独售卖</div>
                                    <div v-show="form.businessType == 2" class="p-price">￥{{ mdl.price }}</div>
                                </div>
                                <div class="p-type">商品类型：{{ form.businessType == 1 ? "课程" : "会员" }}</div>
                            </div>
                        </div>
                        <div class="right-wrap">
                            <a-button type="primary" @click="addProduct">更换商品</a-button>
                        </div>
                    </div>
                </div>
                <a-button v-else type="primary" @click="addProduct">添加商品</a-button>
                <div class="ant-form-explain" v-if="isCheckCouseNo">请添加商品</div>
            </a-form-model-item>
        </a-form-model>

        <FooterToolBar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
        </FooterToolBar>

        <RadioModal
            v-if="visible"
            :modalVisible="visible"
            :tabType="form.businessType"
            :type="0"
            :isShowMember="false"
            :selectedRowKeys="selectedRowKeys"
            @cancel="handleCancel"
            @ok="handleOk"
        />
    </div>
</template>

<script>
import RadioModal from "@/components/courseMember/RadioModal";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import { message } from "ant-design-vue";
import moment from "moment";
import UpdataImg from "@/components/file/updataImg";
import {AddPlaybill, EditPlaybill, GetPlaybill} from "@/request/api/indexManage";
export default {
    mixins: [baseMixin],
    components: { FooterToolBar,RadioModal,UpdataImg },
    created() {
        let id = this.$route.params.id;
        if (id !== "0") {
            this.disabled=true
            this.isShowGoods = true;
            GetPlaybill({ id: id }).then(({ code, data }) => {
                if (code == 200) {
                    this.form = data;
                    this.mdl = data
                    this.form.id = this.$route.params.id;
                    this.imageUrls = data.imageUrl.split(',');
                    this.form.imageUrl = data.imageUrl;
                    this.form.putawayFlag = data.putawayFlag;
                    this.price = data.price;
                    this.form.businessType = data.businessType
                    this.form.courseNo = data.businessNo
                    this.form.businessName = data.productName
                }
            });
        }
    },
    data() {
        return {
            imgUrlFnShow:false,
            imageUrls:[],
            goodsDetail: {}, // 商品详情
            keepLoading: false, // 保存的按钮是否加载
            form: {
                id: "",
                name: "",
                imageUrl: "",
                sort:null,
                businessName:'',
                businessType:1,
                courseNo:'',
                businessNo:'',
                putawayFlag:2,
            },
            rules: {
                name: [
                    {required: true, message: "输入的内容不能为空", trigger: "blur"},
                    {max: 32, message: "长度最长为32个", trigger: "blur"},
                ],
                putawayFlag:[
                    {required: true, message: "请选择上架设置", trigger: "change"},
                ],
                sort:[
                    {required: true, message: "请输入排序", trigger: "blur"},
                ],
                imageUrl:[
                    {required: true, message: ""},
                ],
            },
            disabled:false,
            isShowImag:false,

            isCheckCouseNo: false, // 是否添加商品
            visible: false,
            mdl: null,
            selectedRowKeys: [],
        };
    },
    methods: {
        moment,

        // 添加商品事件
        addProduct(businessType) {
            if (!this.mdl) {
                this.mdl = null;
            }
            this.visible = true;
        },

        // 弹窗中取消按钮事件
        handleCancel() {
            this.visible = false;
        },

        // 弹窗中确认按钮事件
        handleOk(selectedRowKeys, selectedRows, businessType) {
            this.flang = true
            if (businessType == 1 && selectedRows.courseNo) {
                this.mdl = selectedRows;
                this.form.businessType = 1
                this.form.productType = 1
                this.selectedRowKeys = selectedRowKeys;
                this.form.courseNo = selectedRows.courseNo;
                this.form.businessNo = selectedRows.courseNo;
                this.form.businessName = selectedRows.name;
            }else if (businessType == 2 && selectedRows.memberNo) {
                // 会员
                this.mdl = selectedRows;
                this.form.businessType = 2
                this.form.productType = 2
                this.selectedRowKeys = selectedRowKeys;
                this.form.businessNo = selectedRows.memberNo;
                this.form.businessName = selectedRows.memberName;
                this.form.validDays = selectedRows.days;
            }
            this.visible = false;
        },

        // 清除商品信息
        closeHandle() {
            this.mdl = null;
            this.form.businessType = 1;
            this.form.businessNo = "";
            this.form.courseNo = "";
            this.form.businessName = "";
            this.selectedRowKeys = [];
        },

        imgUrlFn(data) {
            this.form.imageUrl = data.toString();
        },

        // 上架设置改变的函数
        putawayFlagChange(e) {
            this.form.putawayFlag = e.target.value;
        },

        // 保存的按钮
        keepClick() {
            this.keepLoading = true;
            setTimeout(() => {
                this.keepLoading = false;
            }, 3000);

            if(!this.form.businessNo){
                this.isCheckCouseNo = true;
                return false
            }else{
                this.isCheckCouseNo = false;
            }

            if(this.imageUrls.length<1){
                this.imgUrlFnShow = true
                return false
            }else{
                this.imgUrlFnShow = false
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if(this.$route.params.id == 0){
                        AddPlaybill(this.form).then(({code}) => {
                            if (code == 200) {
                                message.success("修改成功");
                                location.href = "/indexManage/playbill/playbillList";
                            }
                        });
                    }else{
                        EditPlaybill(this.form).then(({code}) => {
                            if (code == 200) {
                                message.success("修改成功");
                                location.href = "/indexManage/playbill/playbillList";
                            }
                        });
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.ant-form-explain{
    margin-top: 4px;
}
.form_span {
    top: -4px;
}
/deep/.ant-radio-group {
    margin-bottom: 20px;
}
.product-box {
    position: relative;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 4px;
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    .product-wrap {
        height: 86px;
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .product-info {
                flex-direction: column;
                height: 86px;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                .p-price {
                    color: red;
                    line-height: 0;
                }
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
/deep/.edu-type .ant-form-item-children {
    display: flex;
}
/deep/.edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
    width: 100%;
}
/deep/.date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}
date-picker-label {
    margin-left: 10px;
    margin-right: 10px;
}
.product-box {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    .product-wrap {
        height: 86px;
        &.bg {
            background: #ebebeb;
        }
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .product-info {
                flex-direction: column;
                padding-left:15px ;
                //height: 86px;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                .p-price {
                    color: red;
                }
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
/deep/.edu-type .ant-select:nth-child(2) {
    margin-left: 20px;
}
/deep/.edu-quantity .ant-input-number {
    width: 100%;
}
/deep/.date-picker .ant-form-item-children {
    display: flex;
    align-items: center;
}
</style>
